<template>
  <div class="">
    <input
      class="form-control mb-4 search-box"
      type="text"
      placeholder="Search..."
      :value="searchTerm"
      @input="updateSearchTerm"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations(["setSessionSearchFilterByKey"]),
    updateSearchTerm(e) {
      this.setSessionSearchFilterByKey({
        key: "searchTerm",
        value: e.target.value,
      });
    },
  },
  computed: {
    ...mapState({
      searchTerm: (state) =>
        state.sessionSearch.sessionSearchFilters["searchTerm"].value,
    }),
  },
};
</script>

<style scoped></style>
