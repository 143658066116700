<template>
  <!-- V-if there isn't currently a filter of this type selected AND there are items in the filter) -->
  <div
    class="meeting-app-info-block meeting-app-block-collapse"
    :class="{ 'meeting-app-info-open': openOnMobile }"
    v-if="
      !sessionSearchFilters[filterKey].value &&
      items != undefined &&
      Object.keys(items).length !== 0
    "
  >
    <div class="meeting-app-info-header row" @click="toggleOpenOnMobile">
      <h4>{{ filter.display }}</h4>
      <aad-icon name="caret-right" class="collapse-icon"></aad-icon>
    </div>
    <div class="meeting-app-info-body">
      <div
        class="row meeting-app-info-item"
        v-for="(count, key) in items"
        :key="key"
      >
        <a
          href="#"
          @click="
            setSessionSearchFilterByKey({
              key: filterKey,
              value: key,
            })
          "
        >
          {{ key }}
          <span class="tag-pill tag-pill-gray ml-2">
            {{ count }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      openOnMobile: false,
    };
  },
  props: ["items", "filterKey", "filter"],
  methods: {
    ...mapMutations(["setSessionSearchFilterByKey"]),
    toggleOpenOnMobile() {
      this.openOnMobile = !this.openOnMobile;
    },
  },
  computed: {
    ...mapState({
      sessionSearchFilters: (state) => state.sessionSearch.sessionSearchFilters,
    }),
  },
};
</script>
