<template>
  <div class="">
    <a
      href="#"
      class="btn btn-primary-outline w-100 mb-2"
      @click="toggleFavorite"
      v-if="isLoggedIn && !favoriteValue && anyActiveFavorites"
    >
      <aad-icon name="star-filled" class="favorite-star btn-icon"></aad-icon>
      Show Only Favorites</a
    >
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  methods: {
    ...mapMutations(["setSessionSearchFilterByKey"]),
    toggleFavorite() {
      this.setSessionSearchFilterByKey({
        key: "favoritesOnly",
        value: !this.favoriteValue,
      });
    },
  },
  computed: {
    ...mapState({
      favoriteValue: (state) =>
        state.sessionSearch.sessionSearchFilters["favoritesOnly"].value,
    }),
    ...mapGetters(["isLoggedIn", "anyActiveFavorites"]),
  },
};
</script>

<style scoped>
.favorite-star {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #f7d131;
}
</style>
