<template>
  <div class="meeting-app-info-block">
    <div class="meeting-app-info-header row">
      <div class="meeting-app-info-date col-md-9 d-flex align-items-center">
        <a
          @click="toggleFavorite"
          class="favorite-toggle-link"
          v-if="isLoggedIn"
        >
          <aad-icon
            :name="isFavorited ? 'star-filled' : 'star-thick'"
            class="favorite-star"
            :class="isFavorited ? 'star-filled' : 'star-thick'"
          ></aad-icon>
        </a>

        <h3>
          <session-time-string
            :session="session"
            type="start-date"
          ></session-time-string>
        </h3>
      </div>
      <div class="meeting-app-info-time col-md-3">
        <h3>
          <session-time-string
            :session="session"
            type="start-time"
          ></session-time-string>
        </h3>
      </div>
    </div>
    <div class="meeting-app-info-body">
      <div class="row mb-2">
        <router-link
          :to="{ name: 'SessionDetails', params: { id: session.id } }"
          class="col-lg-8 text-link-light-bg pt-2"
          id="searchSessionTitle"
        >
          <h2>{{ session.SessionCode + " - " + session.Name }}</h2>
        </router-link>

        <div class="col-lg-4 session-tags">
          <span
            v-for="category in session.Categories"
            class="tag-pill d-inline-block tag-pill-gray m-1"
            :key="category"
            >{{ category }}</span
          >
        </div>
      </div>
      <div class="row">
        <p class="col-12">
          <aad-icon name="calendar" class="meeting-app-info-svg"></aad-icon>
          <session-time-string :session="session"></session-time-string>
        </p>
        <p class="col-12">
          <aad-icon name="place" class="meeting-app-info-svg"></aad-icon>
          {{ session.LocationName || "Location To Be Announced" }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import SessionTimeString from "@/components/SessionTimeString.vue";
import AadIcon from "./AadIcon.vue";
import { mapGetters } from "vuex";

export default {
  props: ["session"],
  components: { SessionTimeString, AadIcon },
  methods: {
    toggleFavorite() {
      this.$store.dispatch("toggleFavorite", this.session.id);
    },
  },
  computed: {
    ...mapGetters(["isSessionInFavorites", "isLoggedIn"]),
    isFavorited: function () {
      return this.isSessionInFavorites(this.session.id);
    },
  },
};
</script>

<style scoped>
.favorite-star {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.star-filled {
  color: #f7d131;
}

.star-filled:hover {
  color: #f7e79f;
}

.star-thick {
  color: #fff;
}

.star-thick:hover {
  color: #f7e79f;
}
</style>
